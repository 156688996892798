<template lang="">
    <div class="container mx-auto px-4 md:px-0 py-4">
        <div class="flex flex-row items-center justify-between w-full px-2 py-2">
            <router-link :to="{ name: 'piggybanks' }" class="bg-gray-300 rounded-md p-2 mr-2">
                <img src="https://img.icons8.com/?size=512&id=84994&format=png" class="h-6 w-6 transition-all"/>
            </router-link>
            <div class="flex flex-col">
                <div class="text-lg font-semibold">{{ item.emoji }} {{ item.title }}</div>
                <div class="text-xs text-gray-500"></div>
            </div>
            <div class="flex flex-row items-center">
                <div class="p-2 transition-all rounded-md" @click="edit_form_open = !edit_form_open"
                :class="[ !edit_form_open ? 'bg-gray-300': 'bg-gray-400' ]">
                    <img src="https://img.icons8.com/?size=256&id=sP6dvxdjJWj5&format=png" class="h-6 w-6 transition-all"
                    :class="[ !edit_form_open ? 'rotate-0': '-rotate-45' ]"/>
                </div>
            </div>
        </div>
        <div v-if="is_loaded" class="container h-full">
            <div v-if="item != null">
                <div class="flex justify-center items-end" style="height: 170px">
                    <half-circle-plot :numbers="[latestAmount,item.goal]" height="170" class="absolute"/>
                    <div class="flex flex-col items-center">
                        <div class="flex items-center pb-2">
                            <div class="text-4xl font-bold pl-4">
                                {{ new Intl.NumberFormat('fr-FR').format(latestAmount) }}
                            </div>
                            <div class="text-2xl pl-1 pt-1">₸</div>
                        </div>
                        <div class="flex items-center pb-4 text-gray-500">
                            <div class="text-xs font-semibold pl-4">
                                {{ new Intl.NumberFormat('fr-FR').format((latestAmount * 100 / item.goal).toFixed(2)) }}%
                                накоплено из
                                {{ new Intl.NumberFormat('fr-FR').format(item.goal) }}
                            </div>
                            <div class="text-xs pl-1">₸</div>
                        </div>
                    </div>
                </div>
                <div class="overflow-hidden transition-all" :class="[ !edit_form_open ? 'h-0 opacity-0': 'h-56 opacity-100' ]">
                    <div class="transition-all bg-white rounded-md my-4 ">
                        <div class="bg-white p-4 rounded-md">
                            <div class="flex flex-col justify-start items-center">
                                <div class="w-full flex font-semibold p-2">
                                    Редактировать копилку
                                </div>
                                <div class="w-full flex flex-col">
                                    <div class="w-full flex flex-col">
                                        <div class="w-full flex">
                                            <input v-model="item.emoji" class="py-2 px-4 border rounded-tl-md w-1/3" placeholder="♡"/>
                                            <input v-model="item.title" class="py-2 px-4 border rounded-tr-md w-2/3" placeholder="Цель" />
                                        </div>
                                            <div class="w-full flex">
                                            <input v-model="item.goal" class="py-2 px-4 border rounded-bl-md rounded-br-md w-full" placeholder="Сумма" type="number" />
                                        </div>
                                    </div>
                                    <div class="flex pt-2 justify-end">
                                        <div @click="updatePiggy()" class="w-1/2 border rounded-lg p-2 bg-gray-100 flex items-center justify-center">
                                            <img src="https://img.icons8.com/?size=256&id=sP6dvxdjJWj5&format=png" class="h-6 w-6 pr-2"/>
                                            Сохранить
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="[ !add_form_open ? 'h-0 opacity-0': 'h-48 opacity-100' ]" class="overflow-hidden transition-all">
                    <div class="flex flex-col bg-white p-4 rounded-md my-4">
                        <!-- <div class="text-xl p-2 bg-green-300 rounded-full mr-4"></div> -->
                        <div class="pb-2 flex justify-between items-center">
                            <label>Добавить изменение</label>
                        </div>
                        <div class="flex items-center w-full">
                            <input v-model="new_change.amount" class="py-2 h-12 px-4 border rounded-l-md w-1/2" placeholder="00000" type="number"/>
                            <input v-model="new_change.date" class="py-2 h-12 px-4 border rounded-r-md w-1/2" type="date"/>
                        </div>
                        <div class="flex justify-between pt-2">
                            <div @click="add_form_open = !add_form_open" class="py-2 w-1/2 text-center">
                                <label class="text-gray-500">отмена</label>
                            </div>
                            <input @click="storeNewChange()" class="w-1/2 border rounded-lg px-8 py-2 bg-gray-100" type="submit" value="+ Добавить"/>
                        </div>
                    </div>
                </div>
                <div v-if="item?.changes != null && item.changes.length > 0">
                    <div class="flex items-center justify-between pt-8">
                        <div class="p-2">
                            <label class="text-gray-500">График изменений</label>
                        </div>
                    </div>
                    <div class="flex justify-center w-full overflow-x-auto" id="incomesChartWrap">
                        <!-- {{ selected_income_data }} -->
                        <graph-plot :data="graph_data" height="180" width="360" theme="blue" class="bg-white rounded-md my-4"/>
                    </div>
                </div>
                <div v-if="item?.changes != null && item.changes.length > 0">
                    <div class="flex items-center justify-between pt-8">
                        <div class="p-2">
                            <label class="text-gray-500">История изменений</label>
                        </div>
                        <div @click="add_form_open = !add_form_open" class="p-2">
                            <label v-show="!add_form_open" class="text-gray-500">+ Добавить</label>
                        </div>
                    </div>
                    <div class="bg-white rounded-md">
                        <div v-for="change, index in item.changes" :key="change.id" class="p-4 border-b">
                            <div class="flex justify-between items-center">
                                <div class="flex w-full items-center">
                                    <div class="w-1/2 flex flex-col items-start">
                                        <div class="text-lg text-gray-900 p-2 font-semibold">
                                            {{ formattedDate(change.date) }}
                                        </div>
                                    </div>
                                    <div class="w-1/2 pr-2">
                                        <div class="text-lg font-semibold">{{ new Intl.NumberFormat('fr-FR').format(change.amount) }}</div>
                                    </div>
                                    <div class="w-1/2 flex flex-col items-start">
                                        <div class="flex justify-center">
                                            <div class="text-lg font-semibold text-gray-900">
                                                {{ new Intl.NumberFormat('fr-FR').format((change.amount * 100 / item.goal).toFixed(2)) }}
                                            </div>
                                            <div class="text-md font-semibold text-gray-900">
                                                %
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-1/2 flex flex-col items-start">
                                        <div class="flex justify-center">
                                            <div class="text-lg font-semibold text-gray-900"
                                                :class="[ diffPrev(index) > 0 ? 'text-green-700' : 'text-red-700']">
                                                {{ diffPrev(index) !== 0 ?
                                                diffPrev(index) > 0 ?
                                                '+' + new Intl.NumberFormat('fr-FR').format((diffPrev(index)).toFixed(2)) + '%'
                                                : new Intl.NumberFormat('fr-FR').format((diffPrev(index)).toFixed(2)) + '%'
                                                : null }}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="w-1/5 h-6 bg-gray-100">
                                        <div class="h-full bg-gray-700"
                                            :style="{ width: calcPercent(change.amount, item.goal) + '%' }"></div>
                                    </div> -->
                                </div>
                                <div>
                                    <div @click="deleteChange(change.id)" class="ml-4 px-2 opacity-50 hover:opacity-100 active:opacity-100">
                                        <img class="rounded-full w-10 h-6"
                                        src="https://img.icons8.com/?size=256&id=2fUvBuaaxfET&format=png"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="bg-gray-100 flex flex-col items-center justify-center p-8 mt-8">
                    <i>изменений пока нет</i>
                    <div v-show="!add_form_open" @click="add_form_open = !add_form_open" class="py-2 px-4 rounded-md bg-white mt-4">
                        <label class="text-gray-500">+ Добавить</label>
                    </div>
                </div>
                <div class="py-8"></div>
            </div>
            <div v-else class="container mx-auto my-auto flex justify-center items-center h-full bg-gray-100 py-8 rounded-md text-lg mt-4">
                <i>Список копилок пуст</i>
            </div>
        </div>
    </div>
</template>
<script>
import HalfCirclePlot from '@/components/HalfCirclePlot.vue'
import GraphPlot from '@/components/GraphPlot.vue'

export default {
    components: {
        HalfCirclePlot,
        GraphPlot
    },
    data () {
        return {
            edit_form_open: false,
            add_form_open: false,
            is_loaded: false,
            menu_open: false,
            item: {
                id: null,
                title: null,
                changes: null,
                goal: null,
                max: 0
            },
            new_change: {
                amount: null,
                date: null
            }
        }
    },
    computed: {
        latestAmount () {
            if (this.item.changes === null) return 0
            if (this.item.changes === undefined) return 0
            if (this.item.changes.length === 0) return 0
            return this.item.changes[0].amount
        },
        graph_data () {
            if (this.item.changes === null) return
            if (this.item?.changes === undefined) return

            const result = []
            // const limit = this.item.changes.length
            const limit = 12
            for (let i = 0; i < limit; i++) {
                const change = this.item.changes[i]
                const d = new Date(change.date)
                result.push({ date: d.getDate() + '.' + (d.getMonth() + 1), summ: { raw: change.amount, formatted: change.amount } })
            }
            return result.reverse()
            // amount:"4776" date:"2024-09-20" id:"140" piggybank_id:"1"
            // date: "09.22" summ: {raw: 53852, formatted: '0'}
        }
    },
    methods: {
        diffPrev (index) {
            if (this.item.changes[index] === undefined) return 0
            if (this.item.changes[index + 1] === undefined) return 0

            var currItem = this.item.changes[index].amount
            var nextItem = this.item.changes[index + 1].amount

            return (currItem - nextItem) * 100 / this.item.goal
        },
        calcPercent (current, max) {
            var percent = current * 100 / max
            if (percent < 100) return percent
            return 100
        },
        updatePiggy () {
            if (this.item.title === null) return
            const response = this.postServerData('update_piggybank', { params: [this.item.id], data: this.item })
            response.then()
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => {
                        this.edit_form_open = false
                        this.getData()
                    })
        },
        deleteChange (id) {
            const response = this.getServerData('delete_piggybank_change', [id])
            response.then()
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => (this.getData()))
        },
        storeNewChange () {
            if (this.new_change.amount === null && this.new_change.date === null) return

            const response = this.postServerData('store_piggybank_change', { params: [this.item.id], data: this.new_change })
            response.then()
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => (this.getData()))
        },
        getData () {
            const response = this.getServerData('get_piggybank', [this.item.id])
            response.then(response => {
                        this.item = response.data.piggybank
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => (this.is_loaded = true))
        },
        formattedDate (date) {
            const d = new Date(date)
            const zeroPad = (num, places) => String(num).padStart(places, '0')
            return zeroPad(d.getDate(), 2) + '.' + zeroPad(d.getMonth() + 1, 2)
        }
    },
    mounted () {
        this.item.id = this.$route.params.id
        const now = new Date()
        const cdate = new Date(now.getTime() + 360 * 60000).toJSON().slice(0, 10)
        this.new_change.date = cdate

        this.getData()
    }
}
</script>
<style lang=""></style>
