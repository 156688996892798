import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import DashboardView from '../views/DashboardView.vue'
import IncomesList from '../views/Incomes/ListView.vue'
import IncomesCreate from '../views/Incomes/CreateView.vue'
import IncomesEdit from '../views/Incomes/EditView.vue'
import IncomesTagsList from '../views/Incomes/TagsList.vue'
import IncomesTagsCreate from '../views/Incomes/TagsCreate.vue'
import IncomesTagsEdit from '../views/Incomes/TagsEdit.vue'
import ExpensesList from '../views/Expenses/ListView.vue'
import ExpensesEdit from '../views/Expenses/EditView.vue'
import ExpensesCreate from '../views/Expenses/CreateView.vue'
import ExpensesTagsList from '../views/Expenses/TagsList.vue'
import ExpensesTagsCreate from '../views/Expenses/TagsCreate.vue'
import ExpensesTagsEdit from '../views/Expenses/TagsEdit.vue'
import PiggyBanksList from '../views/PiggyBanks/ListView.vue'
import PiggyBanksDetails from '../views/PiggyBanks/DetailsView.vue'
import StashesList from '../views/Stashes/ListView.vue'
import StashesDetails from '../views/Stashes/DetailsView.vue'
import store from '@/store'

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/dash',
        name: 'dash',
        component: DashboardView
    },
    {
        path: '/expenses/list/:date_from?/:date_to?',
        name: 'expenses_list',
        component: ExpensesList
    },
    {
        path: '/expenses/create',
        name: 'expenses_create',
        component: ExpensesCreate
    },
    {
        path: '/expenses/edit/:id',
        name: 'expenses_edit',
        component: ExpensesEdit
    },
    {
        path: '/expenses/tags/list',
        name: 'expenses_tags_list',
        component: ExpensesTagsList
    },
    {
        path: '/expenses/tags/create',
        name: 'expenses_tags_create',
        component: ExpensesTagsCreate
    },
    {
        path: '/expenses/tags/edit/:id',
        name: 'expenses_tags_edit',
        component: ExpensesTagsEdit
    },
    {
        path: '/incomes/list/:date_from?/:date_to?',
        name: 'incomes_list',
        component: IncomesList
    },
    {
        path: '/incomes/create',
        name: 'incomes_create',
        component: IncomesCreate
    },
    {
        path: '/incomes/edit/:id',
        name: 'incomes_edit',
        component: IncomesEdit
    },
    {
        path: '/incomes/tags/list',
        name: 'incomes_tags_list',
        component: IncomesTagsList
    },
    {
        path: '/incomes/tags/create',
        name: 'incomes_tags_create',
        component: IncomesTagsCreate
    },
    {
        path: '/incomes/tags/edit/:id',
        name: 'incomes_tags_edit',
        component: IncomesTagsEdit
    },
    {
        path: '/piggybanks',
        name: 'piggybanks',
        component: PiggyBanksList
    },
    {
        path: '/piggybanks/:id',
        name: 'piggybanks_details',
        component: PiggyBanksDetails
    },
    {
        path: '/stashes',
        name: 'stashes',
        component: StashesList
    },
    {
        path: '/stashes/:id',
        name: 'stashes_details',
        component: StashesDetails
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name === 'login') {
        next()
    } else if (store.getters.isLoggedIn) {
        next()
    } else {
        next({ name: 'login' })
    }
})

export default router
