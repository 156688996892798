<template>
    <canvas v-show="data && data.length > 0" ref="canvas" :height="height" :width="width"></canvas>
</template>
<script>
export default {
    name: 'graph-plot',
    props: ['data', 'height', 'width', 'theme'],
    computed: {
        colors () {
            if (this.theme === 'green') return ['#4ade80', '#15803d', '#6D9A7A', '#374E3E', '#72A281']
            if (this.theme === 'blue') return ['#11538C', '#139DF2', '#0FB2F2', '#1BCBF2', '#5CF2E3']
            return ['#F2C752', '#F28705', '#C98908', '#C88F25', '#DBA521']
        },
        max () {
            var max = 0
            this.data.forEach(item => {
                if (item.summ.raw > max) max = item.summ.raw
            })
            return max
        }
    },
    methods: {
        drawLine (ctx, from, to, color, lineDash = [1, 0]) {
            ctx.beginPath()
            ctx.strokeStyle = color
            ctx.setLineDash(lineDash)
            ctx.moveTo(from[0], from[1])
            ctx.lineTo(to[0], to[1])
            ctx.lineWidth = 1
            ctx.stroke()
            ctx.closePath()
        },
        drawDot (ctx, coords, color) {
            ctx.beginPath()
            ctx.fillStyle = color
            ctx.arc(coords[0], coords[1], 3, 0, 2 * Math.PI)
            // ctx.strokeStyle = color
            ctx.fill()
            // ctx.stroke()
            ctx.closePath()
        },
        drawText (ctx, coords, text, color) {
            ctx.beginPath()
            ctx.font = '8px sans-serif'
            ctx.fillStyle = color
            ctx.fillText(text, coords[0], coords[1])
            // ctx.strokeStyle = color
            ctx.closePath()
        },
        summData () {
            var summ = this.data.reduce((accumulator, item) => {
                return accumulator + item.summ.raw
            }, 0)
            return summ
        },
        calculatePercent (max, current) {
            var percent = current * 100 / max
            return percent
        },
        degToRad (deg) {
            return (deg * Math.PI) / 180
        },
        drawPlotLines (ctx, width, height) {
            var from = [0, height - 15]
            var to = [width * 1, height - 15]
            this.drawLine(ctx, from, to, '#aaa')

            // from = [width * 0.95, 0]
            // to = [width * 0.95, height - 15]
            // this.drawLine(ctx, from, to, 'black')
            // var single = (width * 1) / (this.data.length - 1)
            // for (let i = 0; i < this.data.length; ++i) {
            //     from = [i * single + 10, 0]
            //     to = [i * single + 10, width]
            //     this.drawLine(ctx, from, to, 'black')
            // }
        },
        drawData () {
            var c = this.$refs.canvas
            var ctx = c.getContext('2d')
            ctx.clearRect(0, 0, c.width, c.height)
            if (this.data) {
                this.drawPlotLines(ctx, c.width, c.height)
                var single = (c.width * 0.9) / (this.data.length - 1)
                var lastDot = [0 * single + c.width * 0.05, c.height - 20 - this.calculatePercent(this.max, this.data[0].summ.raw)]
                // var safeAreaX = [0, c.height - 0]
                this.data.forEach((element, index) => {
                    var from = lastDot
                    var coordX = c.height - 20 - this.calculatePercent(this.max, element.summ.raw)
                    // if (this.calculatePercent(this.max, element.summ.raw) === 90) coordX += 0
                    // if (this.calculatePercent(this.max, element.summ.raw) === 0) coordX = c.height - 0
                    var to = [index * single + c.width * 0.05, coordX]
                    this.drawLine(ctx, from, to, this.colors[0]) // graph
                    this.drawLine(ctx, [to[0], c.height - 15], to, this.colors[0], [5, 15]) // vertical line
                    this.drawDot(ctx, to, this.colors[0])
                    this.drawText(ctx, [to[0] - 6, c.height - 5], element.date, this.colors[1])
                    this.drawText(ctx, [to[0] - 4, to[1] - 5], (element.summ.raw / 1000).toFixed(1), this.colors[1])

                    lastDot = to
                })
            }
        }
    },
    mounted () {
        this.drawData()
    },
    watch: {
        data () {
            this.drawData()
        }
    }
}
</script>
