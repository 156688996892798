<template lang="">
    <div class="container mx-auto px-4 md:px-0 py-4">
        <div class="flex flex-row items-center justify-between w-full px-2 py-2">
            <div class="text-lg font-semibold">Категории</div>
            <div class="bg-gray-300 hover:bg-gray-500 rounded-full p-2" @click="menu_open = !menu_open">
                <img src="https://img.icons8.com/?size=512&id=16247&format=png" class="h-6"/>
            </div>
        </div>
        <div class="fixed top-0 left-0 z-[80] w-full h-full overflow-hidden transition-all flex justify-end" :class="[ !menu_open ? 'translate-x-full': null ]">
            <div class="w-10/12  bg-white px-4 md:px-0 py-4 h-full">
                <div class="flex flex-row items-center justify-between w-full px-2 py-2">
                    <div class="text-lg font-semibold">Категории</div>
                    <div class="bg-gray-300 hover:bg-gray-500 rounded-full p-2" @click="menu_open = !menu_open">
                        <img src="https://img.icons8.com/?size=512&id=8112&format=png" class="h-6"/>
                    </div>
                </div>
                <div>
                    <router-link :to="{name: 'incomes_tags_create'}">
                        <div class="border-b-2 py-2">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">➕ Добавить категорию доходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'dash'}">
                        <div class="border-b-2 py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Сводка</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Список расходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_tags_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Категории расходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_create'}">
                        <div class="border-b-2 py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">➕ Добавить в расходы</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Список доходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_tags_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Категории доходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_create'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">➕ Добавить в доходы</div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="is_loaded">
            <div v-for="tag in tags" :key="tag.id" class="rounded-2xl py-2 pr-6 pl-2 my-4"
                :class="[ tag.is_passive === '1' ? 'bg-green-300' : 'bg-white' ]"
                >
                <router-link :to="{ name: 'incomes_tags_edit', params: { id: tag.id }}" class="flex items-center justify-start">
                    <div class="text-2xl rounded-full bg-gray-100 h-12 w-12 flex items-center justify-center">
                        {{ tag.emoji }}
                    </div>
                    <div class="flex flex-col pl-4">
                        <div class="text-lg flex items-start">{{ tag.title }}</div>
                        <div class="text-xs flex items-start text-gray-500">
                            {{ tag.is_passive === '1' ? 'Пассивный' : 'Активный' }}
                            {{ tag.is_recursive === '1' ? 'Рекурсивный' : 'Одноразовый'}}
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            menu_open: false,
            tags: null,
            is_loaded: false
        }
    },
    methods: {
        getData () {
            const response = this.getServerData('get_incomes_tags')
            response.then(response => (this.tags = response.data.data))
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => (this.is_loaded = true))
        }
    },
    mounted () {
        this.getData()
    }
}
</script>
<style scoped></style>
