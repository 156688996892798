<template>
  <router-view/>
</template>

<style>
html, body {
  background-color: #eee;
  height: 100%;
}
#app {
  min-height: 100%;
  display: flex;
  flex-direction: column
}
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar:hover {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  background: #3333;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 0px;
}
</style>
