<template lang="">
    <div class="container mx-auto px-4 md:px-0 py-4">
        <div class="flex flex-row items-center justify-between w-full px-2 py-2">
            <router-link :to="{name: 'expenses_tags_list'}">
                <div class="p-2 pb-0 text-lg font-semibold">{{ tag.emoji }} {{ tag.title }}</div>
                <div class="p-2 pt-0 text-xs">Редактировать категорию</div>
            </router-link>
            <router-link :to="{name: 'expenses_list'}">
                <div class=" hover:bg-gray-500 rounded-full p-2" @click="menu_open = !menu_open">
                    <img src="https://img.icons8.com/?size=512&id=8112&format=png" class="h-6"/>
                </div>
            </router-link>
        </div>
        <div class="flex justify-between">
            <div class="py-2 flex flex-col items-start relative w-3/5">
                <label class="mt-2 px-2 pb-1 font-semibold">Наименование</label>
                <input v-model="tag.title" class="border-2 rounded-md py-2 px-3 w-full"/>
            </div>
            <div class="py-2 flex flex-col items-start relative w-2/5">
                <label class="mt-2 px-2 pb-1 font-semibold">Эмоджи</label>
                <input v-model="tag.emoji" class="border-2 rounded-md py-2 px-3 w-full"/>
            </div>
        </div>
        <div class="py-2 flex flex-col items-start relative">
            <label class="mt-2 px-2 pb-1 font-semibold">Операционный</label>
            <select v-model="tag.is_operational" class="border-2 rounded-md py-2 px-3 w-full bg-white">
                <option value="1">Да</option>
                <option value="0">Нет</option>
            </select>
        </div>
        <div class="flex pt-8 pb-2 justify-between">
            <input @click="saveItem()" type="submit" class="cursor-pointer border-2 rounded-md py-2 px-6 bg-green-500 font-semibold text-white w-3/5" value="Сохранить"/>
            <router-link :to="{name: 'expenses_tags_list'}" class="w-2/5">
                <input class="cursor-pointer border-2 rounded-md py-2 w-full text-center bg-gray-300 text-gray-500" value="Отменить"/>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            tag: {
                id: null,
                title: null,
                emoji: null,
                is_operational: 0
            }
        }
    },
    methods: {
        getItem () {
            const response = this.getServerData('get_expenses_tag', [this.tag.id])
            response.then((response) => (this.tag = response.data.tag))
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => {
                        this.is_loaded = true
                    })
        },
        saveItem () {
            const response = this.postServerData('update_expenses_tag', { params: [this.tag.id], data: this.tag })
            response.then((response) => (console.log(response)))
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => {
                        this.is_loading = false
                        this.$router.push({ name: 'expenses_tags_list' })
                    })
        }
    },
    mounted () {
        this.tag.id = this.$route.params.id
        this.getItem()
    }
}
</script>
<style scoped></style>
