import axios from 'axios'
// axios.defaults.withCredentials = true

export default {
    data: () => {
        return {
            routes: {
                login: 'https://sb.breaktime.kz/api/v1/?a=login',
                get_dash: 'https://sb.breaktime.kz/api/v1/?a=get_dash&tag={$0}&date_from={$1}&date_to={$2}',
                get_dash_year: 'https://sb.breaktime.kz/api/v1/?a=get_dash_year&tag={$0}&date_from={$1}&date_to={$2}',

                get_incomes: 'https://sb.breaktime.kz/api/v1/?a=get_incomes&tag={0}&date_from={$1}&date_to={$2}',
                get_incomes_tags: 'https://sb.breaktime.kz/api/v1/?a=get_incomes_tags',
                get_incomes_tag: 'https://sb.breaktime.kz/api/v1/?a=get_incomes_tag&id={$0}',
                update_incomes_tag: 'https://sb.breaktime.kz/api/v1/?a=update_incomes_tag&id={$0}',
                store_incomes_tag: 'https://sb.breaktime.kz/api/v1/?a=store_incomes_tag',
                get_income: 'https://sb.breaktime.kz/api/v1/?a=get_income&id={$0}',
                store_income: 'https://sb.breaktime.kz/api/v1/?a=store_income',
                update_income: 'https://sb.breaktime.kz/api/v1/?a=update_income&id={$0}',
                delete_income: 'https://sb.breaktime.kz/api/v1/?a=delete_income&id={$0}',
                get_incomes_by_tag: 'https://sb.breaktime.kz/api/v1/?a=get_incomes_by_tag&tag={$0}&date_from={$1}&date_to={$2}',

                get_expenses: 'https://sb.breaktime.kz/api/v1/?a=get_expenses&tag={0}&date_from={$1}&date_to={$2}',
                get_expenses_tags: 'https://sb.breaktime.kz/api/v1/?a=get_expenses_tags',
                get_expenses_tag: 'https://sb.breaktime.kz/api/v1/?a=get_expenses_tag&id={$0}',
                update_expenses_tag: 'https://sb.breaktime.kz/api/v1/?a=update_expenses_tag&id={$0}',
                store_expenses_tag: 'https://sb.breaktime.kz/api/v1/?a=store_expenses_tag',
                get_expense: 'https://sb.breaktime.kz/api/v1/?a=get_expense&id={$0}',
                store_expense: 'https://sb.breaktime.kz/api/v1/?a=store_expense',
                update_expense: 'https://sb.breaktime.kz/api/v1/?a=update_expense&id={$0}',
                delete_expense: 'https://sb.breaktime.kz/api/v1/?a=delete_expense&id={$0}',
                get_expenses_by_tag: 'https://sb.breaktime.kz/api/v1/?a=get_expenses_by_tag&tag={$0}&date_from={$1}&date_to={$2}',

                get_piggybanks: 'https://sb.breaktime.kz/api/v1/?a=get_piggybanks',
                get_piggybank: 'https://sb.breaktime.kz/api/v1/?a=get_piggybank&id={$0}',
                update_piggybank: 'https://sb.breaktime.kz/api/v1/?a=update_piggybank&id={$0}',
                store_piggybank: 'https://sb.breaktime.kz/api/v1/?a=store_piggybank',
                store_piggybank_change: 'https://sb.breaktime.kz/api/v1/?a=store_piggybank_change&piggybank_id={$0}',
                delete_piggybank_change: 'https://sb.breaktime.kz/api/v1/?a=delete_piggybank_change&id={$0}',

                get_stashes: 'https://sb.breaktime.kz/api/v1/?a=get_stashes',
                get_stash: 'https://sb.breaktime.kz/api/v1/?a=get_stash&id={$0}',
                update_stash: 'https://sb.breaktime.kz/api/v1/?a=update_stash&id={$0}',
                store_stash: 'https://sb.breaktime.kz/api/v1/?a=store_stash'
            }
        }
    },
    methods: {
        api_route: function (routeName, params = null) {
            let routeStr = this.routes[routeName]
            if (params) {
                params.forEach((value, index) => {
                    routeStr = routeStr.replace('{$' + index.toString() + '}', value)
                })
            }
            return routeStr
        },
        getServerData: function (routeName, params = null) {
            const baseUrl = this.api_route(routeName, params)
            return axios.get(baseUrl, {
                                headers: {
                                    Authorization: 'alty ' + this.$store.getters.getAT
                                }
                            })
        },
        postServerData: function (routeName, { params = null, data }) {
            const baseUrl = this.api_route(routeName, params)
            return axios.post(baseUrl, data, {
                                headers: {
                                    Authorization: 'alty ' + this.$store.getters.getAT
                                }
                            })
        }
    }
}
