<template>
    <canvas v-show="data && data.length > 0" ref="canvas"></canvas>
</template>
<script>
export default {
    name: 'circle-plot',
    props: ['data', 'height', 'theme'],
    computed: {
        colors () {
            if (this.theme === 'green') return ['#4ade80', '#15803d', '#6D9A7A', '#374E3E', '#72A281']
            if (this.theme === 'blue') return ['#11538C', '#139DF2', '#0FB2F2', '#1BCBF2', '#5CF2E3']
            return ['#F2C752', '#F28705', '#C98908', '#C88F25', '#DBA521']
        }
    },
    methods: {
        init () {
            const ratio = Math.ceil(window.devicePixelRatio)
            const canvas = this.$refs.canvas
            canvas.width = this.height * ratio
            canvas.height = this.height * ratio
            canvas.style.width = `${this.height}px`
            canvas.style.height = `${this.height}px`
            canvas.getContext('2d').setTransform(ratio, 0, 0, ratio, 0, 0)
            this.drawData()
        },
        drawArc (ctx, startAngle, endAngle, color) {
            ctx.beginPath()
            ctx.arc(this.height / 2, this.height / 2, this.height / 2 - this.height / 8, startAngle, endAngle)
            ctx.strokeStyle = color
            ctx.lineWidth = this.height / 6
            ctx.stroke()
            ctx.closePath()
        },
        drawText (ctx, text, posX, posY, color) {
            ctx.font = '14px sans-serif'
            ctx.fillText(text, posX, posY)
        },
        summData () {
            var summ = this.data.reduce((accumulator, currentValue) => {
                return accumulator + currentValue
            }, 0)
            return summ
        },
        calculatePercent (max, current, denominator) {
            var percent = current * denominator / max
            return percent
        },
        degToRad (deg) {
            return (deg * Math.PI) / 180
        },
        drawData () {
            // var c = document.getElementById('canvas')
            const c = this.$refs.canvas
            const ctx = c.getContext('2d')
            let startAngle = 1.5 * Math.PI
            let colorsIndex = 0
            this.data.forEach((element, index) => {
                const percent = this.calculatePercent(this.summData(), element, 360)
                const endAngle = this.degToRad(percent)
                this.drawArc(ctx, startAngle, startAngle + endAngle, this.colors[colorsIndex])
                startAngle += endAngle
                colorsIndex++
                if (colorsIndex === this.colors.length) colorsIndex = 0
            })
            // this.data.forEach((element, index) => {
            //     const percent10 = Math.round(this.calculatePercent(this.summData(), element, 100))
            //     this.drawText(ctx, percent10, 27 + index * 20, 50, this.colors[colorsIndex])
            // })
            // this.drawText(ctx, 99, 37, 49, this.colors[colorsIndex])
        }
    },
    mounted () {
        this.init()
    },
    watch: {
        data () {
            this.drawData()
        }
    }
}
</script>
