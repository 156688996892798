<template lang="">
    <div class="container mx-auto px-4 md:px-0 py-4">
        <div class="flex flex-row items-center justify-between w-full px-2 py-2">
            <router-link :to="{ name: 'stashes' }" class="bg-gray-300 rounded-full p-2 mr-2">
                <img src="https://img.icons8.com/?size=512&id=84994&format=png" class="h-6 transition-all"/>
            </router-link>
            <div class="flex flex-col">
                <div class="text-lg font-semibold">Долги</div>
                <div class="text-xs text-gray-500"></div>
            </div>
            <div class="flex flex-row items-center">
                <div class="bg-gray-300 rounded-full p-2" @click="menu_open = !menu_open">
                    <img src="https://img.icons8.com/?size=512&id=16247&format=png" class="h-6"/>
                </div>
            </div>
        </div>
        <div class="fixed top-0 left-0 z-[80] w-full h-full overflow-hidden transition-all flex justify-end" :class="[ !menu_open ? 'translate-x-full': null ]">
            <div class="w-10/12  bg-white px-4 md:px-0 py-4 h-full">
                <div class="flex flex-row items-center justify-between w-full px-2 py-2">
                    <div class="text-lg font-semibold">Доходы</div>
                    <div class="bg-gray-300 hover:bg-gray-500 rounded-full p-2" @click="menu_open = !menu_open">
                        <img src="https://img.icons8.com/?size=512&id=8112&format=png" class="h-6"/>
                    </div>
                </div>
                <div>
                    <router-link :to="{name: 'dash'}">
                        <div class="border-b-2 py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Сводка</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Список расходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_tags_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Категории расходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_create'}">
                        <div class="border-b-2 py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">➕ Добавить в расходы</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Список доходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_tags_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Категории доходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_create'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">➕ Добавить в доходы</div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="is_loaded" class="container h-full">
            <div v-if="item != null">
                <div class="bg-white p-4 rounded-md my-4 transition-all">
                    <div class="flex justify-start items-center" @dblclick="edit_form_open = !edit_form_open">
                        <div class="text-xl p-2 bg-gray-100 rounded-full mr-4">{{ item.emoji }}</div>
                        <div>
                            <div class="text-lg font-semibold">{{ item.title }}</div>
                            <div class="text-xs text-gray-300">
                                {{ item.goal > 0 ? item.goal : null }}
                            </div>
                        </div>
                    </div>
                    <div class="overflow-hidden transition-all" :class="[ !edit_form_open ? 'h-0 opacity-0': 'h-30 opacity-100' ]">
                        <div class="bg-white py-4 rounded-md my-4 border-t">
                            <div class="flex flex-col justify-start items-center">
                                <div class="w-full flex font-semibold p-2">
                                    Редактировать запись
                                </div>
                                <div class="w-full flex">
                                    <input v-model="item.emoji" class="py-2 px-4 border rounded-l-md w-1/5" placeholder="♡"/>
                                    <input v-model="item.title" class="py-2 px-4 border w-4/5" placeholder="Цель" />
                                </div>
                                <div class="w-full flex">
                                    <input v-model="item.amount" class="py-2 px-4 border rounded-r-md w-1/2" placeholder="Сумма" />
                                    <input v-model="item.duedate" class="py-2 px-4 border rounded-r-md w-1/2 bg-white" type="date" />
                                </div>
                                    <div class="w-full flex">
                                    <select v-model="item.type" class="py-2 px-4 border rounded-r-md w-1/2 bg-white">
                                        <option value="0" selected>Кредит</option>
                                        <option value="1">Дебит</option>
                                    </select>
                                    <input @click="updateItem()" class="border rounded-md w-1/2 h-10 bg-gray-100" type="submit" value="сохранить"/>
                                </div>
                                <div class="w-full flex">
                                </div>
                                <div class="w-full">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-8"></div>
            </div>
            <div v-else class="container mx-auto my-auto flex justify-center items-center h-full bg-gray-100 py-8 rounded-md text-lg mt-4">
                <i>Список копилок пуст</i>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            edit_form_open: false,
            is_loaded: false,
            menu_open: false,
            item: {
                id: null,
                title: null,
                changes: null,
                goal: null,
                max: 0
            },
            new_change: {
                amount: null,
                date: null
            }
        }
    },
    methods: {
        updateItem () {
            if (this.item.title === null) return
            const response = this.postServerData('update_stash', { params: [this.item.id], data: this.item })
            response.then()
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => {
                        this.edit_form_open = false
                        this.getData()
                    })
        },
        getData () {
            const response = this.getServerData('get_stash', [this.item.id])
            response.then(response => {
                        this.item = response.data.item
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => (this.is_loaded = true))
        }
    },
    mounted () {
        this.item.id = this.$route.params.id
        const now = new Date()
        const cdate = new Date(now.getTime() + 360 * 60000).toJSON().slice(0, 10)
        this.new_change.date = cdate

        this.getData()
    }
}
</script>
<style lang=""></style>
