<template>
    <span class="transition-all" :class="[ is_loading ? 'opacity-70' : 'opacity-100']">
        {{ isPublic ? new Intl.NumberFormat('fr-FR').format(cur_value.toFixed(decimals)) : '...' }}
    </span>
</template>
<script>
export default {
    name: 'animated-number',
    props: {
        value: {
            type: [Number, String],
            default: 0
        },
        decimals: {
            type: Number,
            default: 0
        },
        isPublic: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            cur_value: 0,
            max_value: 0,
            update_interval: null,
            is_loading: false
        }
    },
    methods: {
        calculateNumber () {
            if (Math.abs(this.cur_value - this.value) > 10000) {
                if (this.cur_value < this.value) this.cur_value += 10000
                if (this.cur_value > this.value) this.cur_value -= 10000
            } else if (Math.abs(this.cur_value - this.value) <= 10000 && Math.abs(this.cur_value - this.value) > 1000) {
                if (this.cur_value < this.value) this.cur_value += 1000
                if (this.cur_value > this.value) this.cur_value -= 1000
            } else if (Math.abs(this.cur_value - this.value) <= 1000 && Math.abs(this.cur_value - this.value) > 100) {
                if (this.cur_value < this.value) this.cur_value += 100
                if (this.cur_value > this.value) this.cur_value -= 100
            } else if (Math.abs(this.cur_value - this.value) <= 100 && Math.abs(this.cur_value - this.value) > 1) {
                if (this.cur_value < this.value) this.cur_value += 1
                if (this.cur_value > this.value) this.cur_value -= 1
            } else if (Math.abs(this.cur_value - this.value) <= 1) {
                if (this.cur_value < this.value) this.cur_value += 0.01
                if (this.cur_value > this.value) this.cur_value -= 0.01
            }
            if (Math.abs(this.cur_value - this.value) < 0.01) {
                clearInterval(this.update_interval)
                this.is_loading = false
            }
        },
        animateNumber () {
            this.cur_value = 0
            this.is_loading = true
            this.update_interval = setInterval(this.calculateNumber, 10)
        }
    },
    mounted () {
        this.animateNumber()
    },
    watch: {
        value: 'animateNumber'
    }
}
</script>
