<template lang="">
    <div class="container mx-auto text-black flex px-4 md:px-0 py-4 justify-end">
        <router-link :to="{name: 'expenses_tags_list'}">
            <div class=" hover:bg-gray-500 rounded-full p-2" @click="menu_open = !menu_open">
                <img src="https://img.icons8.com/?size=512&id=8112&format=png" class="h-6"/>
            </div>
        </router-link>
    </div>
    <div class="container mx-auto px-4 md:px-0 py-4">
        <div class="py-2 flex flex-col items-start relative">
            <label class="mt-2 px-2 pb-1 font-semibold">Наименование</label>
            <input v-model="tag.title" class="border-2 rounded-md py-2 px-3 w-full" placeholder="Наименование"/>
        </div>
        <div class="py-2 flex flex-col items-start relative">
            <label class="mt-2 px-2 pb-1 font-semibold">Эмоджи</label>
            <input v-model="tag.emoji" class="border-2 rounded-md py-2 px-3 w-full" placeholder="Эмоджи"/>
        </div>
        <div class="py-2 flex flex-col items-start relative">
            <label class="mt-2 px-2 pb-1 font-semibold">Операционный</label>
            <select v-model="tag.is_operational" class="border-2 rounded-md py-2 px-3 w-full bg-white">
                <option value="1">Да</option>
                <option value="0">Нет</option>
            </select>
        </div>
        <div class="py-2 flex pt-8">
            <input @click="saveItem()" type="submit" class="cursor-pointer border-2 rounded-md py-2 px-6 bg-green-500 font-semibold text-white" value="Сохранить"/>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            tag: {
                title: null,
                emoji: null,
                is_operational: 0
            }
        }
    },
    methods: {
        saveItem () {
            const response = this.postServerData('store_expenses_tag', { data: this.tag })
            response.then((response) => (console.log(response)))
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => {
                        this.is_loading = false
                        this.$router.push({ name: 'expenses_tags_list' })
                    })
        }
    },
    mounted () {
    }
}
</script>
<style scoped></style>
