<template lang="">
    <div class="container mx-auto px-4 md:px-0 py-4">
        <div class="flex flex-row items-center justify-between w-full py-2">
            <router-link :to="{ name: 'dash' }" class="bg-gray-300 rounded-md p-2 mr-2">
                <img src="https://img.icons8.com/?size=512&id=84994&format=png" class="h-6 transition-all"/>
            </router-link>
            <div class="flex flex-col items-center">
                <div class="text-lg font-semibold">Доходы {{ meta.summ  }}</div>
                <div class="text-xs text-gray-500" @click="date_filter_open = !date_filter_open">{{ date_from_local  }} - {{ date_to_local }}</div>
            </div>
            <div class="flex flex-row items-center">
                <div class="bg-gray-300 rounded-md p-2" @click="menu_open = !menu_open">
                    <img src="https://img.icons8.com/?size=512&id=16247&format=png" class="h-6"/>
                </div>
            </div>
        </div>
        <div v-if="date_filter_open" class="flex flex-row justify-around items-center py-4">
            с <input v-model="date_from" @change="changeDate()" type="date" class="bg-white p-2 rounded-md w-2/5" />
            до <input v-model="date_to" @change="changeDate()" type="date" class="bg-white p-2 rounded-md w-2/5" />
        </div>
        <div class="fixed top-0 left-0 z-[80] w-full h-full overflow-hidden transition-all flex justify-end" :class="[ !menu_open ? 'translate-x-full': null ]">
            <div class="w-10/12  bg-white px-4 md:px-0 py-4 h-full">
                <div class="flex flex-row items-center justify-between w-full px-2 py-2">
                    <div class="text-lg font-semibold">Доходы</div>
                    <div class="bg-gray-300 hover:bg-gray-500 rounded-full p-2" @click="menu_open = !menu_open">
                        <img src="https://img.icons8.com/?size=512&id=8112&format=png" class="h-6"/>
                    </div>
                </div>
                <div>
                    <router-link :to="{name: 'dash'}">
                        <div class="border-b-2 py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Сводка</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Список расходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_tags_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Категории расходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_create'}">
                        <div class="border-b-2 py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">➕ Добавить в расходы</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Список доходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_tags_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Категории доходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_create'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">➕ Добавить в доходы</div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="is_loaded" class="container h-full">
            <div v-if="items != null && items.length > 0">
                <div class="bg-white rounded-md p-4 text-sm">
                    <div v-for="tag in meta.tags" :key="tag.id" class="flex py-1 items-center transition-all" @click="filterByTag(tag.id)" :class="[ selected_tag != null ? selected_tag == tag.id ? 'opacity-100' : 'opacity-30' : null ]">
                        <div class="w-4/5">
                            {{ tag.emoji }}
                            {{ tag.title }}
                        </div>
                        <div class="w-3/5 flex justify-end bg-gray-50 rounded-full">
                            <div class="w-full h-2 bg-indigo-100 rounded-full transition-all" :style="{ width: parseFloat((parseInt(tag.summ.replaceAll(' ','')) * 100) / meta.max).toFixed(2) + '%'}">
                            </div>
                        </div>
                        <div class="w-3/5 flex justify-between">
                            <div class="text-right text-gray-300 w-1/6">
                                {{ tag.amount }}
                            </div>
                            <div class="text-right w-4/5">
                                {{ tag.summ }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="selected_tag != null">
                    <div class="p-4 pb-0 font-semibold flex justify-between">
                        <div>
                            {{ selected_tag_data.emoji }}
                            {{ selected_tag_data.title }}
                        </div>
                        <div>
                            {{ selected_tag_data.amount }}
                        </div>
                    </div>
                </div>
                <div v-for="income in items" :key="income.id" class="transition-all overflow-hidden"
                    :class="[ selected_tag != null ? selected_tag != income.main_tag ? 'h-0' : null : null ]">
                    <div class="rounded-2xl bg-white py-2 pr-6 pl-2 mt-4 flex flex-col">
                        <div class="flex flex-row items-center justify-between">
                            <div class="text-lg rounded-full bg-gray-100 h-10 w-10 flex items-center justify-center">
                                {{ income.emoji }}
                            </div>
                            <div class="flex flex-col items-end w-full" @click="selected_item_id == income.id ? selected_item_id = null : selected_item_id = income.id">
                                <div class="flex items-center w-full justify-between pl-2 transition-all flex-row">
                                    <div class="flex flex-col items-start w-full">
                                        <div class="transition-all" :class="[ selected_item_id == income.id ? 'text-xs text-gray-900' : 'text-xs text-gray-500' ]">
                                            {{ income.description.length != 0 ? income.description.length > 30 && selected_item_id != income.id ? income.description.slice(0, 27) + '...' : income.description : income.title}}
                                        </div>
                                    </div>
                                    <div class="flex flex-col items-end w-full">
                                        <div class="text-lg flex items-start">+{{ income.amount }}<div class="text-xs pl-1 pt-1">₸</div></div>
                                        <div class="text-gray-500 text-sm flex overflow-hidden transition-all">
                                            {{ selected_item_id != income.id ? income.date_converted : income.date }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-xs overflow-hidden transition-all" :class="[ selected_item_id == income.id ? 'h-10' : 'h-0' ]">
                            <div class="flex flex-row flex-wrap text-right justify-center pt-2">
                                <router-link :to="{ name: 'incomes_edit', params: { id: income.id }}">
                                    <div class="px-3 py-2 rounded-full bg-gray-100">Редактировать</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="py-8"></div>
            </div>
            <div v-else class="container mx-auto my-auto flex justify-center items-center h-full bg-gray-100 py-8 rounded-md text-lg mt-4">
                <i>Список доходов пуст</i>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            selected_tag: null,
            selected_item_id: null,
            menu_open: false,
            date_filter_open: false,
            date_from: null,
            date_to: null,
            items: null,
            is_loaded: false,
            meta: {
                summ: 0,
                tags: null
            },
            cdate: null
        }
    },
    computed: {
        date_to_local () {
            if (this.date_to == null) return null
            const dateTo = this.date_to.split('-')
            let resultStr = dateTo[2] + '.' + dateTo[1]
            if (this.cdate[0] !== dateTo[0]) resultStr += '.' + dateTo[0]
            return resultStr
        },
        date_from_local () {
            if (this.date_from == null) return null
            const dateFrom = this.date_from.split('-')
            let resultStr = dateFrom[2] + '.' + dateFrom[1]
            if (this.cdate[0] !== dateFrom[0]) resultStr += '.' + dateFrom[0]
            return resultStr
        },
        selected_tag_data () {
            if (this.selected_tag != null) {
                return this.meta.tags.filter(item => item.id === this.selected_tag)[0]
            } else {
                return null
            }
        }
    },
    methods: {
        filterByTag (tagId) {
            this.selected_item_id = null
            if (this.selected_tag === tagId) {
                this.selected_tag = null
            } else {
                this.selected_tag = tagId
            }
        },
        getData () {
            const response = this.getServerData('get_incomes', ['all', this.date_from, this.date_to])
            response.then(response => {
                        this.items = response.data.data
                        this.meta = response.data.meta
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => (this.is_loaded = true))
        },
        changeDate () {
            this.$router.push({ name: 'incomes_list', params: { date_from: this.date_from, date_to: this.date_to } })
        }
    },
    mounted () {
        const now = new Date()
        const cdate = new Date(now.getTime() + 360 * 60000).toJSON().slice(0, 10)
        this.cdate = cdate.split('-')
        this.date_from = this.cdate[0] + '-' + this.cdate[1] + '-01'
        this.date_to = this.cdate[0] + '-' + this.cdate[1] + '-' + this.cdate[2]

        if (this.$route.params.date_from != null) this.date_from = this.$route.params.date_from
        if (this.$route.params.date_to != null) this.date_to = this.$route.params.date_to

        this.getData()
    },
    watch: {
        $route (to, from) {
            if (to.name === this.$route.name) this.getData()
        }
    }
}
</script>
<style scoped>
    .menu_open{
        height: 100%
    }
</style>
