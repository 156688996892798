<template lang="">
    <div class="container mx-auto px-4 md:px-0 py-4">
        <div class="flex flex-row items-center justify-between w-full px-2 py-2">
            <router-link :to="{ name: 'dash' }" class="bg-gray-300 rounded-full p-2 mr-2">
                <img src="https://img.icons8.com/?size=512&id=84994&format=png" class="h-6 transition-all"/>
            </router-link>
            <div class="flex flex-col">
                <div class="text-lg font-semibold">Долги</div>
                <div class="text-xs text-gray-500"></div>
            </div>
            <div class="flex flex-row items-center">
                <div class="bg-gray-300 rounded-full p-2 mr-2" @click="is_new_item_form_open = !is_new_item_form_open">
                    <img src="https://img.icons8.com/?size=512&id=3220&format=png" class="h-6 transition-all" :class="{ 'rotate-90': is_new_item_form_open }"/>
                </div>
                <div class="bg-gray-300 rounded-full p-2" @click="menu_open = !menu_open">
                    <img src="https://img.icons8.com/?size=512&id=16247&format=png" class="h-6"/>
                </div>
            </div>
        </div>
        <div class="flex w-full overflow-hidden transition-all" :class="[ !is_new_item_form_open ? 'h-0': null ]">
            <div class="bg-white p-4 rounded-md my-4">
                <div class="flex flex-col justify-start items-center">
                    <div class="w-full flex">
                        <input v-model="new_item.emoji" class="py-2 px-4 border rounded-l-md w-1/3" placeholder="😍"/>
                        <input v-model="new_item.title" class="py-2 px-4 border w-2/3" placeholder="Цель" />
                        <input v-model="new_item.amount" class="py-2 px-4 border rounded-r-md w-2/3" placeholder="Сумма" />
                    </div>
                    <div class="w-full flex">
                        <input v-model="new_item.duedate" class="py-2 px-4 border rounded-r-md w-2/3" type="date" />
                        <select v-model="new_item.type" class="py-2 px-4 border rounded-r-md w-2/3">
                            <option value="0" selected>Кредит</option>
                            <option value="1">Дебит</option>
                        </select>
                        <input @click="storeItem()" class="border rounded-full w-24 h-10 ml-2 bg-gray-100" type="submit" value="+"/>
                    </div>
                    <div class="w-full">
                    </div>
                </div>
            </div>
        </div>
        <div class="fixed top-0 left-0 z-[80] w-full h-full overflow-hidden transition-all flex justify-end" :class="[ !menu_open ? 'translate-x-full': null ]">
            <div class="w-10/12  bg-white px-4 md:px-0 py-4 h-full">
                <div class="flex flex-row items-center justify-between w-full px-2 py-2">
                    <div class="text-lg font-semibold">Доходы</div>
                    <div class="bg-gray-300 hover:bg-gray-500 rounded-full p-2" @click="menu_open = !menu_open">
                        <img src="https://img.icons8.com/?size=512&id=8112&format=png" class="h-6"/>
                    </div>
                </div>
                <div>
                    <router-link :to="{name: 'dash'}">
                        <div class="border-b-2 py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Сводка</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Список расходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_tags_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Категории расходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'expenses_create'}">
                        <div class="border-b-2 py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">➕ Добавить в расходы</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Список доходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_tags_list'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">✨ Категории доходов</div>
                        </div>
                    </router-link>
                    <router-link :to="{name: 'incomes_create'}">
                        <div class="py-0">
                            <div class="py-2 px-4 my-4 hover:bg-gray-100">➕ Добавить в доходы</div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-if="is_loaded" class="container h-full">
            <div v-if="items != null && items.length > 0">
                <div v-for="item in items" :key="item.id" class="bg-white p-4 rounded-md my-4 button">
                    <router-link :to="{ name: 'stashes_details', params: { 'id': item.id }}">
                        <div class="flex justify-start items-center">
                            <div class="text-xl p-2 bg-gray-100 rounded-full mr-4">{{ item.emoji }}</div>
                            <div class="w-full">
                                <div class="flex w-full justify-between">
                                    <div class="text-lg font-semibold flex">
                                        <div>{{ item.type === '0' ? '-' : '+' }}</div>
                                        <div>{{ item.amount > 0 ? item.amount : '--' }}</div>
                                    </div>
                                    <div class="">
                                        {{ item.title }}
                                    </div>
                                    <!-- <div>{{ item.updated_at }}</div> -->
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="py-8"></div>
            </div>
            <div v-else class="container mx-auto my-auto flex justify-center items-center h-full bg-gray-100 py-8 rounded-md text-lg mt-4">
                <i>Список долгов пуст</i>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            is_loaded: false,
            is_new_item_form_open: false,
            new_item: {
                emoji: '♡',
                title: null,
                amount: null,
                type: 0,
                duedate: null
            },
            menu_open: false,
            items: null
        }
    },
    methods: {
        storeItem () {
            if (this.new_item.title === null) return
            const response = this.postServerData('store_stash', { data: this.new_item })
            response.then(() => {
                        this.new_item = { emoji: '♡', title: null, amount: null, type: 0, duedate: null }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => (this.getData()))
        },
        getData () {
            const response = this.getServerData('get_stashes')
            response.then(response => {
                                this.items = response.data.data
                            })
                            .catch(function (error) {
                                // handle error
                                console.log(error)
                            })
                            .finally(() => (this.is_loaded = true))
        }
    },
    mounted () {
        this.getData()
    }
}
</script>
<style>
    .button {
        opacity: 100%;
        transform: scale(1);
        transition: all .3s;
    }
    .button:active {
        opacity: 20%;
        transform: scale(.95);
    }
</style>
