import { createStore } from 'vuex'

export default createStore({
    state () {
        return {
            at: null
        }
    },
    getters: {
        isLoggedIn (state) {
            return state.at !== null
        },
        getAT (state) {
            return state.at
        }
    },
    mutations: {
        logIn (state, at) {
            state.at = at
        }
    },
    actions: {
    },
    modules: {
    }
})
