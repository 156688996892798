<template lang="">
    <div class="container mx-auto text-black flex px-4 md:px-0 pt-4">
        <router-link :to="{name: 'incomes_list'}">
            <div class="py-2 px-4 rounded-full">&larr; Назад</div>
        </router-link>
    </div>
    <div class="container mx-auto text-black my-auto mx-auto p-4 pb-24 md:p-0">
        <div class="py-2 flex font-semibold justify-center text-lg text-gray-700">
            Редактировать Запись
        </div>
        <div class="py-2 mb-4 flex flex-col items-center">
            <div class="flex flex-row items-center justify-center border-2 rounded-full px-4 bg-white md:w-1/3 w-2/3">
                <label class="mt-2 px-2 pb-1 font-semibold">KZT</label>
                <input v-model="income.amount" type="number" class="outline-none py-2 text-4xl w-2/3 rounded-md text-right" placeholder="00000" min="0" max="99999"/>
            </div>
        </div>
        <div class="py-2 px-4 mb-4 flex flex-row bg-white items-center relative rounded-md ">
            <label class="px-2">
                <img src="https://img.icons8.com/?size=512&id=13705&format=png" class="h-6"/>
            </label>
            <select v-if="is_tags_loaded" v-model="income.main_tag.id" class="py-2 px-3 w-full bg-white">
                <option value="0" disabled>Выберите категорию</option>
                <option v-for="tag in tags" v-bind:key="tag.id" :value="tag.id">{{ tag.title }}</option>
                <option value="-1">Создать новую</option>
            </select>
            <div v-else class="py-2 px-3">
                <i>Загрузка</i>
            </div>
        </div>
        <div v-if="income.main_tag.id == -1" class="py-2 px-4 mb-4 flex flex-row bg-white items-center relative rounded-md ">
            <label class="px-2">
                <img src="https://img.icons8.com/?size=512&id=1501&format=png" class="h-6 w-6"/>
            </label>
            <input v-model="income.main_tag.title"  class="rounded-md py-2 px-3 w-3/6" placeholder="Название новой категории"/>
            <input v-model="income.main_tag.emoji"  class="rounded-md py-2 px-3 w-2/6" placeholder="🤍"/>
        </div>
        <div class="py-2 px-4 mb-4 flex flex-row bg-white items-center relative rounded-md ">
            <label class="px-2">
                <img src="https://img.icons8.com/?size=512&id=TZQlTUfG7rbB&format=png" class="h-6"/>
            </label>
            <input v-model="income.description" class="rounded-md py-2 px-3 w-full" placeholder="Описание" />
        </div>
        <div class="py-2 px-4 mb-4 flex flex-row bg-white items-center relative rounded-md ">
            <label class="px-2">
                <img src="https://img.icons8.com/?size=512&id=23&format=png" class="h-6"/>
            </label>
            <input v-model="income.date" class="py-2 px-3 w-full bg-white"  placeholder="--" type="date"/>
        </div>
        <div class="py-2  flex justify-between items-center w-full">
            <input @click="saveIncome()" type="submit" class="cursor-pointer rounded-lg py-4 px-6 w-4/5 bg-green-500 font-semibold text-white uppercase" value="Сохранить"/>
            <div @click="deleteIncome()" class="w-1/5 flex justify-center pl-2">
                <div class="">
                    <img src="https://img.icons8.com/?size=512&id=11705&format=png" class="h-12 w-12 rounded-full p-3"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            income: {
                id: null,
                date: null,
                amount: null,
                main_tag: {
                    id: 0,
                    title: null,
                    emoji: null
                },
                description: null
            },
            tags: [],
            is_tags_loaded: false
        }
    },
    methods: {
        deleteIncome () {
            if (confirm('Удалить запись?')) {
                const response = this.getServerData('delete_income', [this.income.id])
                response.then((response) => (console.log(response)))
                            .catch(function (error) {
                                // handle error
                                console.log(error)
                            })
                            .finally(() => {
                                this.$router.push({ name: 'incomes_list' })
                            })
            }
        },
        saveIncome () {
            const response = this.postServerData('update_income', { params: [this.income.id], data: this.income })
            response.then((response) => (console.log(response)))
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => {
                        this.$router.push({ name: 'incomes_list' })
                    })
        },
        getIncome () {
            // const baseUrl = 'https://sb.breaktime.kz/api/v1/?a=get_income&id=' + this.income.id
            const response = this.getServerData('get_income', [this.income.id])
            response.then((response) => {
                        this.income.date = response.data.income.date
                        this.income.amount = response.data.income.amount
                        this.income.main_tag.id = response.data.income.main_tag
                        this.income.description = response.data.income.description
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => {
                        this.is_tags_loaded = true
                    })
        },
        getTags () {
            // const baseUrl = 'https://sb.breaktime.kz/api/v1/?a=get_incomes_tags'
            const response = this.getServerData('get_incomes_tags')
            response.then((response) => (this.tags = response.data.data))
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => {
                        this.is_tags_loaded = true
                    })
        }
    },
    mounted () {
        this.income.id = this.$route.params.id
        this.getTags()
        this.getIncome()

        // let cdate = new Date().toJSON().slice(0, 10)
        // cdate = cdate.split('-')
        // this.expense.date.year = cdate[0]
        // this.expense.date.month = cdate[1]
        // this.income.date.day = cdate[2]
    }
}
</script>
<style scoped></style>
