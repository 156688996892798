<template lang="">
    <div class="container mx-auto px-4 md:px-0 py-4">
        <div class="flex flex-row items-center justify-between w-full px-2 py-2">
            <router-link :to="{ name: 'dash' }" class="bg-gray-300 rounded-md p-2 mr-2">
                <img src="https://img.icons8.com/?size=512&id=84994&format=png" class="h-6 transition-all"/>
            </router-link>
            <div class="flex flex-col">
                <div class="text-lg font-semibold">Копилки</div>
                <div class="text-xs text-gray-500"></div>
            </div>
            <div class="flex flex-row items-center">
                <div class="bg-gray-300 rounded-md p-2 mr-2" @click="is_new_piggybank_form_open = !is_new_piggybank_form_open">
                    <img src="https://img.icons8.com/?size=512&id=3220&format=png" class="h-6 w-6 transition-all" :class="{ 'rotate-45': is_new_piggybank_form_open }"/>
                </div>
            </div>
        </div>
        <div class="flex w-full overflow-hidden transition-all" :class="[ !is_new_piggybank_form_open ? 'h-0 opacity-0': 'h-60 opacity-100' ]">
            <div class="bg-white p-4 rounded-md my-4">
                <div class="flex flex-col justify-start items-center">
                    <div class="w-full pb-2 pl-2">
                        <label class="text-lg font-semibold">Добавить копилку</label>
                    </div>
                    <div class="w-full">
                        <div class="w-full flex">
                            <input v-model="new_piggy.emoji" class="py-2 px-4 border rounded-l-md w-1/3" placeholder="😍"/>
                            <input v-model="new_piggy.title" class="py-2 px-4 border w-2/3" placeholder="Цель" required />
                        </div>
                        <div class="w-full flex">
                            <input v-model="new_piggy.goal" class="py-2 px-4 border rounded-r-md w-full" placeholder="Сумма" type="number" required />
                        </div>
                        <div class="w-full flex pt-2 justify-end">
                            <input @click="storeNewPiggy()" class="border rounded-md py-2 px-6 bg-gray-100" type="submit" value="+ Добавить"/>
                        </div>
                    </div>
                    <div class="w-full">
                    </div>
                </div>
            </div>
        </div>
        <div v-if="is_loaded" class="container h-full">
            <div v-if="items != null && items.length > 0">
                <div v-for="item in items" :key="item.id" class="bg-white p-4 rounded-md my-4 button relative">
                    <router-link :to="{ name: 'piggybanks_details', params: { 'id': item.id }}">
                        <div class="flex justify-start items-center">
                            <div class="text-xl p-2 rounded-md mr-4 relative  bg-gray-100">
                                <div class="relative z-10">{{ item.emoji }}</div>
                                <div :class="[ item.goal == 0 ? 'hidden' : null ]" class="absolute bottom-0 left-0 h-full w-full z-[0] flex items-end">
                                    <div class="bg-green-100 w-full rounded-md" :style="{ height: item.amount * 100 / item.goal + '%'}"></div>
                                </div>
                            </div>
                            <div class="w-full flex flex-col pb-1">
                                <div class="w-full flex justify-between items-center">
                                    <div class="text-lg font-semibold">{{ item.title }}</div>
                                    <!-- <div :class="[ item.goal == 0 ? 'hidden' : null ]" class="pt-2">
                                        <div class="bg-gray-100 rounded-md">
                                            <div class="bg-indigo-300 h-2 rounded-md" :style="{ width: item.amount * 100 / item.goal + '%'}"></div>
                                        </div>
                                    </div> -->
                                    <div v-if="item.amount > 0" class="text-sm text-gray-500">{{ (item.amount * 100 / item.goal).toFixed(2) + '%' }}</div>
                                </div>
                                <div class="w-full flex items-center text-xs text-gray-900">
                                    <div>{{ item.amount > 0 ? new Intl.NumberFormat('fr-FR').format(item.amount) : null }}</div>
                                    <div v-if="item.amount > 0" class="px-1">из</div>
                                    <div>{{ item.goal > 0 ? new Intl.NumberFormat('fr-FR').format(item.goal) : null }}</div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="py-8"></div>
            </div>
            <div v-else class="container mx-auto my-auto flex justify-center items-center h-full bg-gray-100 py-8 rounded-md text-lg mt-4">
                <i>Список копилок пуст</i>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            is_loaded: false,
            is_new_piggybank_form_open: false,
            new_piggy: {
                emoji: '♡',
                title: null,
                goal: null
            },
            items: null
        }
    },
    methods: {
        storeNewPiggy () {
            if (this.new_piggy.goal === null) return
            if (this.new_piggy.title === null) return
            const response = this.postServerData('store_piggybank', { data: this.new_piggy })
            response.then()
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => (this.getData()))
        },
        getData () {
            const response = this.getServerData('get_piggybanks')
            response.then(response => {
                        this.items = response.data.data
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => (this.is_loaded = true))
        }
    },
    mounted () {
        this.getData()
    }
}
</script>
<style>
    .button {
        opacity: 100%;
        transform: scale(1);
        transition: all .3s;
    }
    .button:active {
        opacity: 20%;
        transform: scale(.95);
    }
</style>
