<template lang="">
    <div class="container mx-auto text-black flex px-4 md:px-0 py-4 justify-end">
        <a @click="$router.go(-1)">
            <div class=" hover:bg-gray-500 rounded-full p-2" @click="menu_open = !menu_open">
                <img src="https://img.icons8.com/?size=512&id=8112&format=png" class="h-6"/>
            </div>
        </a>
    </div>
    <div class="container mx-auto text-black my-auto mx-auto p-4 pb-24 md:p-0">
        <div class="py-4 flex justify-center text-2xl text-gray-700">
            Добавить Доход
        </div>
        <div class="py-2 mb-4 flex flex-col items-center">
            <div class="flex flex-row items-center justify-center border-2 rounded-full px-6 bg-white md:w-1/2 w-4/5">
                <label class="mt-2 px-2 pb-1 font-semibold">KZT</label>
                <input v-model="income.amount" type="number" class="outline-none p-2 text-4xl w-full rounded-md text-right" placeholder="0000000" min="0"/>
            </div>
        </div>
        <div class="py-2 px-4 flex flex-row bg-white items-center relative rounded-md ">
            <label class="px-2">
                <img src="https://img.icons8.com/?size=512&id=13705&format=png" class="h-6 w-6"/>
            </label>
            <select  v-if="is_tags_loaded" v-model="income.main_tag.id" class="py-2 px-3 w-full bg-white">
                <option value="0" disabled>Категория</option>
                <option v-for="tag in tags" v-bind:key="tag.id" :value="tag.id">{{ tag.emoji + ' ' + tag.title }}</option>
                <option value="-1">Создать новую</option>
            </select>
            <div v-else class="py-1 px-3 h-9">
                <i>Загрузка...</i>
            </div>
        </div>
        <div v-if="is_tags_loaded" class="flex flex-row overflow-x-hidden w-full gap-2 text-xs py-4 mb-4">
            <div v-for="tag in popular_tags" :key="tag.id" @click="income.main_tag.id = tag.id" class="px-4 py-2 rounded-full border whitespace-none" :class="[ income.main_tag.id == tag.id ? 'bg-green-100' : 'bg-white']">{{ tag.emoji }}</div>
        </div>
        <div v-if="income.main_tag.id == -1" class="py-2 px-4 mb-4 flex flex-row bg-white items-center justify-between relative rounded-md ">
            <label class="px-2">
                <img src="https://img.icons8.com/?size=512&id=1501&format=png" class="h-6 w-6"/>
            </label>
            <input v-model="income.main_tag.title"  class="py-2 px-3 w-3/6" placeholder="Название новой категории"/>
            <input v-model="income.main_tag.emoji"  class="py-2 px-3 w-2/6" placeholder="🤍"/>
        </div>
        <div class="py-2 px-4 mb-4 flex flex-row bg-white items-center relative rounded-md ">
            <label class="px-2">
                <img src="https://img.icons8.com/?size=512&id=TZQlTUfG7rbB&format=png" class="h-6 w-6"/>
            </label>
            <input v-model="income.description" class="rounded-md py-2 px-3 w-full" placeholder="Описание" />
        </div>
        <div class="py-2 px-4 mb-4 flex flex-row bg-white items-center relative rounded-md ">
            <label class="px-2">
                <img src="https://img.icons8.com/?size=512&id=23&format=png" class="h-6 w-6"/>
            </label>
            <input v-model="income.date" class="rounded-md py-2 px-3 w-full bg-white"  placeholder="Сегодня" type="date"/>
        </div>
        <div class="py-2  flex justify-center w-full">
            <input @click="saveIncome(false)" type="submit" class="cursor-pointer rounded-lg py-4 px-6 w-full bg-green-500 font-semibold text-white uppercase" value="Сохранить"/>
        </div>
        <div class="py-2  flex justify-center w-full">
            <input @click="saveIncome(true)" type="submit" class="cursor-pointer rounded-lg py-2 px-6 bg-gray-300 text-white" value="сохранить и добавить еще"/>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            income: {
                date: null,
                amount: null,
                main_tag: {
                    id: 0,
                    title: null,
                    emoji: null
                },
                description: null
            },
            tags: [],
            is_tags_loaded: false
        }
    },
    methods: {
        saveIncome (stay) {
            if (this.income.amount === null) return alert('Пожалуйста, заполните сумму')
            if (this.income.main_tag.id === 0) return alert('Пожалуйста, выберите категорию')
            const response = this.postServerData('store_income', { data: this.income })
            response.then((response) => (console.log(response)))
                            .catch(function (error) {
                                // handle error
                                console.log(error)
                            })
                            .finally(() => {
                                if (stay) {
                                    this.income.amount = null
                                    this.income.main_tag = { id: 0, title: null, emoji: null }
                                    this.income.description = null
                                } else this.$router.push({ name: 'incomes_list' })
                            })
        },
        getTags () {
            const response = this.getServerData('get_incomes_tags')
            response.then((response) => (this.tags = response.data.data))
                    .catch(function (error) {
                        // handle error
                        console.log(error)
                    })
                    .finally(() => {
                        this.is_tags_loaded = true
                    })
        }
    },
    computed: {
        popular_tags () {
            return this.tags.slice(0, 7)
        }
    },
    mounted () {
        this.getTags()

        const cdate = new Date().toJSON().slice(0, 10)
        this.income.date = cdate
    }
}
</script>
<style scoped></style>
